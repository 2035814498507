<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="blue accent-3">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >Detalle de la solicitud de extensión de fecha límite</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <!-- <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              Save
            </v-btn>
          </v-toolbar-items> -->
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <div class="text-h4 font-weight-medium">
              Información general
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              class="mx-auto py-5 px-7 my-5"
            >
              <v-row>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">Asignatura o módulo</div>
                    <span class="text-h6">
                      {{ limitDateExtensionRequest.subject }}
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">Evaluación</div>
                    <span class="text-h6">
                      {{ limitDateExtensionRequest.evaluation_type }}
                    </span>
                  </div>
                </v-col>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">Perfil</div>
                    <span class="text-h6">
                      {{ limitDateExtensionRequest.evaluation }}
                    </span>
                  </div>
                </v-col>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">Periodo</div>
                    <span class="text-h6">
                      {{ limitDateExtensionRequest.stage }}
                    </span>
                  </div>
                </v-col>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">
                      Última fecha para ingreso de notas
                    </div>
                    <span class="text-h6">
                      {{ limitDateExtensionRequest.limit_date_formatted }}
                    </span>
                  </div>
                </v-col>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">
                      Porcentaje correspondiente a la evaluación
                    </div>
                    <span class="text-h6">
                      {{
                        `${limitDateExtensionRequest.sub_evaluation_percent_formatted} de ${limitDateExtensionRequest.evaluation_percent_formatted}`
                      }}
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">Descripción de la evaluación</div>
                    <span class="text-h6">
                      {{ limitDateExtensionRequest.sub_evaluation_description }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-h4 font-weight-medium mb-4">
              Nueva fecha límite establecida:
              <strong>{{ limitDate }}</strong>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-h4 font-weight-medium">
              Seguimiento de la solicitud
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              class="mx-auto py-5 px-7 my-5"
            >
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(item,
                  index) in limitDateExtensionRequest.request_trackings"
                  :key="index"
                  :color="colors[item.request_status_id % 17]"
                  small
                >
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong>{{ item.created_at }}</strong>
                    </v-col>
                    <v-col>
                      <strong>{{ item.request_status }}</strong>
                      <div class="text-body-1 text--grey text--darken-2">
                        {{ item.user_full_name }}
                      </div>
                      <p class="mt-2">
                        {{ item.justification }}
                      </p>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LimitDateExtensionDetailModal",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    limitDateExtensionRequest: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    limitDate() {
      if (!this.limitDateExtensionRequest.limit_date_extension_detail) {
        return "Ninguna";
      }
      return this.limitDateExtensionRequest?.limit_date_extension_detail
        ?.new_limit_date_formatted;
    },
  },
};
</script>
