<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="675"
    >
      <v-form>
        <v-card>
          <v-toolbar dark color="blue accent-4" class="elevation-0 px-3">
            <span class="headline">Revisión de extensión de fecha límite</span>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <div class="text-h5 font-weight-medium">
                    Información general
                  </div>
                  <v-sheet
                    rounded="xl"
                    color="white"
                    elevation="2"
                    class="mx-auto py-5 px-7 mt-5"
                  >
                    <v-row>
                      <v-col class="pt-0">
                        <div class="black--text mb-1 flex-column">
                          <div class="font-weight-medium">
                            Asignatura o módulo
                          </div>
                          <span class="text-h6">
                            {{ limitDateExtensionRequest.subject }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0">
                        <div class="black--text mb-1 flex-column">
                          <div class="font-weight-medium">Perfil</div>
                          <span class="text-h6">
                            {{ limitDateExtensionRequest.evaluation }}
                          </span>
                        </div>
                      </v-col>
                      <v-col class="pt-0">
                        <div class="black--text mb-1 flex-column">
                          <div class="font-weight-medium">Periodo</div>
                          <span class="text-h6">
                            {{ limitDateExtensionRequest.stage }}
                          </span>
                        </div>
                      </v-col>
                      <v-col class="pt-0">
                        <div class="black--text mb-1 flex-column">
                          <div class="font-weight-medium">
                            Porcentaje correspondiente a la evaluación
                          </div>
                          <span class="text-h6">
                            {{
                              `${limitDateExtensionRequest.sub_evaluation_percent_formatted} de ${limitDateExtensionRequest.evaluation_percent_formatted}`
                            }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="text-h5 font-weight-medium">
                    Detalle de la solicitud
                  </div>
                </v-col>
              </v-row>
              <v-sheet
                rounded="xl"
                color="white"
                elevation="2"
                class="mx-auto py-5 px-7 mt-5"
              >
                <v-row>
                  <v-col class="pt-0">
                    <div class="black--text mb-1 flex-column">
                      <div class="font-weight-medium mb-2">
                        Razón de la solicitud de extensión de perfil
                      </div>
                      <span>
                        {{ limitDateExtensionRequest.request_reason }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <div class="black--text mb-1 flex-column">
                      <div class="font-weight-medium mb-2">
                        Justificación
                      </div>
                      <p class="mb-0">
                        {{ limitDateExtensionRequest.justification }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0 pb-3">
            <v-btn text class="mr-1" @click="closeModal">Cancelar</v-btn>
            <v-btn
              color="blue accent-4"
              class="mr-1"
              dark
              outlined
              text
              @click="toggleReviewModal(), setHasBeenApproved(false)"
              >No aprobar</v-btn
            >
            <v-btn
              color="blue accent-4"
              dark
              depressed
              @click="toggleReviewModal(), setHasBeenApproved(true)"
              >Aprobar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialog2" max-width="500px">
      <v-form>
        <v-card>
          <v-toolbar
            :dark="!reviewPayload.has_been_approved"
            :color="
              reviewPayload.has_been_approved
                ? 'green accent-2'
                : 'red accent-2'
            "
            class="elevation-0 px-3"
          >
            <span class="headline"
              >Antes de finalizar el proceso de revisión</span
            >
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col col="12" class="pb-0">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    v-model="newLimitDateMenu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <form-group
                        name="nueva fecha límite para ingreso de notas"
                        :validator="$v.reviewPayload.new_limit_date"
                        v-bind="attrs"
                      >
                        <v-text-field
                          v-model="reviewPayload.new_limit_date"
                          label="Nueva fecha límite para ingreso de notas"
                          placeholder="Nueva fecha límite para ingreso de notas"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          required
                          filled
                          rounded
                          dense
                          background-color="grey lighten-4"
                          color="black"
                          :disabled="!reviewPayload.has_been_approved"
                          v-bind="attrs"
                          slot-scope="{ attrs }"
                          v-on="on"
                        ></v-text-field>
                      </form-group>
                    </template>
                    <v-date-picker
                      v-model="reviewPayload.new_limit_date"
                      :min="new Date().toISOString().slice(0, 10)"
                      @input="newLimitDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <form-group
                    name="justificación"
                    :validator="$v.reviewPayload.justification"
                  >
                    <v-textarea
                      slot-scope="{ attrs }"
                      v-bind="attrs"
                      filled
                      rounded
                      dense
                      background-color="grey lighten-4"
                      color="black"
                      counter
                      label="Justificación de la revisión"
                      auto-grow
                      rows="3"
                      v-model="reviewPayload.justification"
                    ></v-textarea>
                  </form-group>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0">
            <v-btn text outlined class="mr-1" @click="closeModal2"
              >Cancelar</v-btn
            >
            <v-btn
              :color="
                reviewPayload.has_been_approved
                  ? 'green accent-2'
                  : 'red accent-2'
              "
              :dark="!reviewPayload.has_been_approved"
              depressed
              :loading="isLoading"
              @click="submit"
              >Procesar solicitud</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import limitDateExtensionRepository from "@/repositories/limitDateExtensionRepository";

export default {
  name: "RequestLimitDateExtensionReviewModal",

  data() {
    return {
      isLoading: false,
      dialog: false,
      dialog2: false,
      newLimitDateMenu: false,
      reviewPayload: {
        has_been_approved: false,
        justification: "Solicitud de extensión de fecha límite aprobada.",
        new_limit_date: null,
      },
    };
  },

  props: {
    limitDateExtensionRequest: {
      type: Object,
    },
    userId: {
      type: Number,
    },
  },

  methods: {
    review() {
      this.isLoading = true;
      const payload = {
        ...this.reviewPayload,
        request_id: this.limitDateExtensionRequest.id,
        user_id: this.userId,
      };
      limitDateExtensionRepository
        .reviewLimitDateExtensionRequest(payload)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.closeModal();
            this.closeModal2();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible procesar la solicitud 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.review();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },
    toggleReviewModal() {
      this.dialog2 = !this.dialog2;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
    },
    closeModal2() {
      this.toggleReviewModal();
      this.$v.$reset();
      this.reviewPayload.justification =
        "Solicitud de extensión de fecha límite aprobada.";
    },

    setHasBeenApproved(approved) {
      this.reviewPayload.has_been_approved = !!approved;
    },
  },
  validations: {
    reviewPayload: {
      new_limit_date: {
        required: requiredIf(function() {
          return !!this.reviewPayload.has_been_approved;
        }),
      },
      justification: {
        required,
      },
    },
  },
  watch: {
    dialog2(newValue) {
      if (newValue && !this.reviewPayload.has_been_approved) {
        this.reviewPayload.justification = "";
      }
    },
  },
};
</script>

<style scoped>
p,
span {
  font-size: 17px !important;
}
</style>
