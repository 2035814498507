<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Solicitudes
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar las solicitudes según su
              categoría.</span
            >
          </h3>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body">
        <v-tabs v-model="tab" centered grow>
          <v-tab href="#tab-sc">Correcciones de calificaciones</v-tab>
          <v-tab href="#tab-lde">Extensión de perfiles</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-sc">
            <!-- begin:: scores correction request list -->
            <v-card
              elevation="2"
              class="ma-1 min-h-65vh px-4 px-md-5 px-lg-7 py-2"
            >
              <v-card-text class="text-h5 font-weight-medium">
                Solicitudes de corrección de calificaciones
              </v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-select
                      :label="
                        requestStatuses.isLoading
                          ? 'Cargando estados'
                          : 'Estado de la solicitud'
                      "
                      :loading="requestStatuses.isLoading"
                      :disabled="!requestStatuses.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="requestStatuses.data"
                      item-text="name"
                      item-value="id"
                      v-model="scoresCorrection.filters.last_request_status_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{
                            scoresCorrection.filters.last_request_status_id
                              .length - 1
                          }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      :label="
                        scoresCorrection.isLoading
                          ? 'Cargando docentes e instructores'
                          : 'Docente o instructor'
                      "
                      :loading="scoresCorrection.isLoading"
                      :disabled="!teachers.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="teachers"
                      item-text="full_name"
                      item-value="id_user"
                      v-model="scoresCorrection.filters.user_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.first_name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ scoresCorrection.filters.user_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- <v-col>
                    <v-autocomplete
                      :label="subjects.isLoading ? 'Cargando asignaturas' : 'Asignatura'"
                      :loading="subjects.isLoading"
                      :disabled="!subjects.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="subjects.data"
                      item-text="name"
                      item-value="id"
                      v-model="scoresCorrection.filters.subject_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ scoresCorrection.filters.subject_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle class="mt-1">
                            <span>
                              Grado
                              <strong class="ml-1">{{
                                data.item.grade
                              }}</strong>
                            </span>
                            <span class="ml-2">
                              Especialidad
                              <strong class="ml-1">{{
                                data.item.speciality
                              }}</strong>
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="scoresCorrection.headers"
                      :items="scoresCorrectionFilteredData"
                      :search="scoresCorrection.search"
                      :items-per-page="10"
                      :loading="scoresCorrection.isLoading"
                    >
                      <template v-slot:[`item.evaluation`]="{ item }">
                        <p class="m-0 my-1 text-wrap">
                          <strong>{{ item.evaluation_type }}</strong> del <strong>{{ item.sub_evaluation_percent_formatted }}</strong> del perfil <strong>{{ item.evaluation }}</strong> de la asignatura <strong>{{ item.subject }}</strong>
                        </p>
                      </template>
                      <!-- begin: Actions -->

                      <template v-slot:[`item.actions`]="{ item }">
                        <template
                          v-if="
                            existInArray(
                              'Revisar solicitud de corrección de notas',
                              currentPageActions
                            )
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="goToScoresAmendFormReview(item)"
                                color="pink accent-3"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="item.last_request_status_id != 1"
                              >
                                mdi-book-check
                              </v-icon>
                            </template>
                            <span>Revisar solicitud</span>
                          </v-tooltip>
                        </template>
                        <template
                          v-if="
                            existInArray(
                              'Ver solicitudes de correción de notas',
                              currentPageActions
                            )
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="
                                  selectScoresCorrection(item);
                                  showMdlRequestDetail();
                                "
                                color="lime accent-4"
                                class="ml-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-format-list-checks
                              </v-icon>
                            </template>
                            <span>Ver detalle</span>
                          </v-tooltip>
                        </template>
                      </template>
                      <!-- end: Acions -->
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <!-- end:: scores correction request list -->
          </v-tab-item>
          <v-tab-item value="tab-lde">
            <!-- begin:: sub evaluation limit date extension -->
            <v-card
              elevation="2"
              class="ma-1 min-h-65vh px-4 px-md-5 px-lg-7 py-2"
            >
              <v-card-text class="text-h5 font-weight-medium">
                Solicitudes de extensión de fecha límite de evaluaciones
              </v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-select
                      :label="
                        requestStatuses.isLoading
                          ? 'Cargando estados'
                          : 'Estado de la solicitud'
                      "
                      :loading="requestStatuses.isLoading"
                      :disabled="!requestStatuses.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="requestStatuses.data"
                      item-text="name"
                      item-value="id"
                      v-model="
                        limitDateExtensions.filters.last_request_status_id
                      "
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{
                            limitDateExtensions.filters.last_request_status_id
                              .length - 1
                          }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      :label="
                        limitDateExtensions.isLoading
                          ? 'Cargando docentes e instructores'
                          : 'Docente o instructor'
                      "
                      :loading="limitDateExtensions.isLoading"
                      :disabled="!teachers.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="teachers"
                      item-text="full_name"
                      item-value="id_user"
                      v-model="limitDateExtensions.filters.user_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.first_name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ limitDateExtensions.filters.user_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="limitDateExtensions.headers"
                      :items="limitDateExtensionsFilteredData"
                      :search="limitDateExtensions.search"
                      :items-per-page="10"
                      :loading="limitDateExtensions.isLoading"
                    >
                      <template v-slot:[`item.evaluation`]="{ item }">
                        <p class="m-0 my-1 text-wrap">
                          <strong>{{ item.evaluation_type }}</strong> del <strong>{{ item.sub_evaluation_percent_formatted }}</strong> del perfil <strong>{{ item.evaluation }}</strong> de la asignatura <strong>{{ item.subject }}</strong>
                        </p>
                      </template>
                      <!-- begin: Actions -->

                      <template v-slot:[`item.actions`]="{ item }">
                        <!-- begin:: answering request -->
                        <template
                          v-if="
                            existInArray(
                              'Revisar solicitud de extensión de perfil',
                              currentPageActions
                            )
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="
                                  selectLimitDateExtension(item);
                                  showMdlRequestLimitDateExtensionReview();
                                "
                                color="pink accent-3"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="item.last_request_status_id != 1"
                              >
                                mdi-book-check
                              </v-icon>
                            </template>
                            <span>Revisar solicitud</span>
                          </v-tooltip>
                        </template>
                        <!-- end:: answering request  -->

                        <!-- begin:: request "somewhat" summary -->
                        <template
                          v-if="
                            existInArray(
                              'Ver solicitudes de extensión de perfil',
                              currentPageActions
                            )
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="
                                  selectLimitDateExtension(item);
                                  showMdlLimitDateExtensionDetail();
                                "
                                color="lime accent-4"
                                class="ml-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-format-list-checks
                              </v-icon>
                            </template>
                            <span>Ver detalle</span>
                          </v-tooltip>
                        </template>
                        <!-- end:: request "somewhat" summary -->
                      </template>
                      <!-- end: Acions -->
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <!-- end:: sub evaluation limit date extension -->
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <RequestDetailModal
      ref="mdlRequestDetail"
      :request="selectedScoresCorrection"
    ></RequestDetailModal>
    <LimitDateExtensionDetailModal
      ref="mdlLimitDateExtensionDetail"
      :limitDateExtensionRequest="selectedLimitDateExtension"
    ></LimitDateExtensionDetailModal>
    <RequestLimitDateExtensionReviewModal
      ref="mdlRequestLimitDateExtensionReview"
      :limitDateExtensionRequest="selectedLimitDateExtension"
      @reload-data="loadLimitDateExtensionRequests"
      :userId="currentUserPersonalInfo.id_user"
    ></RequestLimitDateExtensionReviewModal>
    <!-- end::Modals -->
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FETCH_USERS } from "@/core/services/store/role.module.js";
import { mapGetters } from "vuex";
import requestStatusRepository from "@/repositories/requestStatusRepository";
import subjectRepository from "@/repositories/subjectRepository";
import requestRepository from "@/repositories/requestRepository";
import limitDateExtensionRepository from "@/repositories/limitDateExtensionRepository";
import RequestDetailModal from "@/components/modals/requests/RequestDetailModal.vue";
import LimitDateExtensionDetailModal from "@/components/modals/requests/LimitDateExtensionDetailModal.vue";
import DeferredLateArrivalRequestDetailModal from "@/components/modals/requests/deferredLateArrivalRequestDetailModal.vue";
import RequestLimitDateExtensionReviewModal from "@/components/modals/requests/RequestLimitDateExtensionReviewModal.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "Requests",
  title: "Solicitudes | GE ITR",
  components: {
    RequestDetailModal,
    LimitDateExtensionDetailModal,
    RequestLimitDateExtensionReviewModal,
    DeferredLateArrivalRequestDetailModal,
  },
  props: {
    // f of from
    f: {
      type: String,
    },
    // Se recibe el id de la solicitud de extensión
    lde: {
      type: String,
    },
  },
  data() {
    return {
      scoresCorrection: {
        headers: [
          { text: "Razón de la solicitud", value: "request_reason" },
          { text: "Solicitada por", value: "user_full_name" },
          { text: "Detalle de evaluación", value: "evaluation" },
          { text: "Estado de la solicitud", value: "last_request_status" },
          { text: "Fecha de solicitud", value: "created_at" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      limitDateExtensions: {
        headers: [
          { text: "Razón de la solicitud", value: "request_reason" },
          { text: "Solicitada por", value: "user_full_name" },
          { text: "Detalle de evaluación", value: "evaluation" },
          { text: "Estado de la solicitud", value: "last_request_status" },
          { text: "Fecha de solicitud", value: "created_at" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      requestStatuses: { data: [], isLoading: false },
      selectedScoresCorrection: {},
      selectedLimitDateExtension: {},
      subjects: { data: [], isLoading: false },
      tab: null,
      userTypesAllowedToSeeInformaticaEducativa: [
        "Coordinación técnica",
        "Superadmin",
      ],
      informaticaEducativaStr: "Informática educativa",
      requestStatusColorById: {
        1: {
          color: "amber",
          icon: "clock",
        },
        2: {
          color: "success",
          icon: "check-circle",
        },
        3: {
          color: "red",
          icon: "close-circle",
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Solicitudes", route: "requests" },
    ]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Solicitudes");
    this.$store.dispatch(FETCH_USERS);
    this.getRequestStatuses();
    this.loadScoresCorrectionRequests();
    this.loadLimitDateExtensionRequests();
    this.getSubjects();
    this.setTabByParams();
  },
  methods: {
    loadScoresCorrectionRequests() {
      this.scoresCorrection.isLoading = true;
      requestRepository
        .getRequestWithOldestRequestTracking()
        .then((response) => {
          this.scoresCorrection.data = response.data;
        })
        .catch((err) => {
          console.error(err);
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.scoresCorrection.isLoading = false;
        });
    },
    loadLimitDateExtensionRequests() {
      this.limitDateExtensions.isLoading = true;
      limitDateExtensionRepository
        .getLimitDateExtensionRequestWithOldestRequestTracking()
        .then((response) => {
          this.limitDateExtensions.data = response.data;
          if (this.lde) {
            ApiService.get(`${this.baseApiUrl}/version/uu/${this.lde}`).then(
              ({ data }) => {
                const item = this.limitDateExtensions.data.find(
                  (item) => item.id == data
                );
                this.selectLimitDateExtension(item);
                if (item.last_request_status_id == 1) {
                  this.showMdlRequestLimitDateExtensionReview();
                } else {
                  this.fireToast({
                    icon: "info",
                    title: "La solicitud ya ha sido procesada",
                  });
                }
                this.tab = "tab-lde";
              }
            );
          }
        })
        .catch((err) => {
          console.error(err);
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.limitDateExtensions.isLoading = false;
        });
    },

    getRequestStatuses() {
      this.requestStatuses.isLoading = true;
      requestStatusRepository
        .getAllRequestStatuses()
        .then(({ data }) => {
          this.requestStatuses.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.requestStatuses.isLoading = false;
        });
    },
    selectScoresCorrection(obj) {
      // Creating a non reactive copie of data
      this.selectedScoresCorrection = { ...obj };
    },
    selectLimitDateExtension(obj) {
      // Creating a non reactive copie of data
      this.selectedLimitDateExtension = { ...obj };
    },
    showMdlRequestDetail() {
      this.$refs.mdlRequestDetail.toggleModal();
    },
    showMdlLimitDateExtensionDetail() {
      this.$refs.mdlLimitDateExtensionDetail.toggleModal();
    },
    showMdlRequestLimitDateExtensionReview() {
      this.$refs.mdlRequestLimitDateExtensionReview.toggleModal();
    },
    goToScoresAmendFormReview({ idE }) {
      this.$router.push({
        path: "score_correction",
        query: {
          r: idE,
        },
      });
    },
    getSubjects() {
      this.subjects.isLoading = true;
      subjectRepository
        .getAllSubjects()
        .then(({ data }) => {
          this.subjects.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.subjects.isLoading = false;
        });
    },
    setTabByParams() {
      if ("lde" in this.$router.history.current.query) {
        this.tab = "tab-lde";
      }
    },
    getRequestStatusColor(requestStatusId) {
      const defaultColor = "grey darken-1";

      return (
        this.requestStatusColorById[requestStatusId]?.color ?? defaultColor
      );
    },

    getRequestStatusIcon(requestStatusId) {
      const defaultIcon = "account";

      return this.requestStatusColorById[requestStatusId]?.icon ?? defaultIcon;
    },
  },
  computed: {
    ...mapGetters([
      "userType",
      "currentPageActions",
      "currentUserPersonalInfo",
    ]),
    getUserType() {
      const { user_type: userType } = this.userType;
      return userType;
    },
    scoresCorrectionFilteredData() {
      const restrictedData = this.scoresCorrection.data.filter((item) => {
        if (item.subject == this.informaticaEducativaStr) {
          return this.userTypesAllowedToSeeInformaticaEducativa.some(
            (userType) => userType === this.getUserType
          );
        }

        return item.user_types_allowed_to_see_notification.some(
          (userType) => userType === this.getUserType
        ) || this.getUserType == 'Superadmin';
      });
      return this.filterData(this.scoresCorrection.filters, restrictedData);
    },
    limitDateExtensionsFilteredData() {
      const restrictedData = this.limitDateExtensions.data.filter((item) => {
        if (item.subject == this.informaticaEducativaStr) {
          return this.userTypesAllowedToSeeInformaticaEducativa.some(
            (userType) => userType === this.getUserType
          );
        }

        return item.user_types_allowed_to_see_notification.some(
          (userType) => userType === this.getUserType
        ) || this.getUserType == 'Superadmin';
      });
      return this.filterData(this.limitDateExtensions.filters, restrictedData);
    },

    teachers() {
      let userTypes = [];
      if (process.env.APP_ENV === "local") {
        userTypes.push("Superadministrador");
        userTypes.push("Superadmin");
      } else {
        userTypes.push("Docente académico");
        userTypes.push("Docente técnico");
      }

      let arrTeachers = this.$store.getters.getUsersByUserType(userTypes);
      let data = arrTeachers.map((item) => {
        return {
          ...item,
          full_name: item.first_name + " " + item.last_name,
        };
      });
      return data;
    },
    baseApiUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DEV_API_URL;
      } else {
        baseURL = process.env.LOCAL_API_URL;
      }
      return baseURL;
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },

  watch: {
    lde(newValue) {
      if (newValue) {
        this.setTabByParams();
        this.loadLimitDateExtensionRequests();
      }
    },
  },
};
</script>

<style scoped>
.min-h-65vh {
  min-height: 65vh !important;
}
</style>
