<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="light-blue">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >Detalle de la solicitud de correción de notas</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <!-- <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              Save
            </v-btn>
          </v-toolbar-items> -->
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <div class="text-h4 font-weight-medium">
              Información general
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              class="mx-auto py-5 px-7 my-5"
            >
              <v-row>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">Asignatura o módulo</div>
                    <span class="text-h6">
                      {{ request.subject }}
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">Evaluación</div>
                    <span class="text-h6">
                      {{ request.evaluation_type }}
                    </span>
                  </div>
                </v-col>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">Perfil</div>
                    <span class="text-h6">
                      {{ request.evaluation }}
                    </span>
                  </div>
                </v-col>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">Periodo</div>
                    <span class="text-h6">
                      {{ request.stage }}
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">
                      Última fecha para ingreso de notas
                    </div>
                    <span class="text-h6">
                      {{ request.limit_date_formatted }}
                    </span>
                  </div>
                </v-col>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">
                      Porcentaje correspondiente a la evaluación
                    </div>
                    <span class="text-h6">
                      {{
                        `${request.sub_evaluation_percent_formatted} de ${request.evaluation_percent_formatted}`
                      }}
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <div class="black--text mb-1 flex-column">
                    <div class="font-weight-medium">Descripción de la evaluación</div>
                    <span class="text-h6">
                      {{ request.sub_evaluation_description }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-h4 font-weight-medium">
              Seguimiento de la solicitud
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              class="mx-auto py-5 px-7 my-5"
            >
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(item, index) in request.request_trackings"
                  :key="index"
                  :color="colors[item.request_status_id % 17]"
                  small
                >
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong>{{ item.created_at }}</strong>
                    </v-col>
                    <v-col>
                      <strong>{{ item.request_status }}</strong>
                      <div class="text-body-1 text--grey text--darken-2">
                        {{ item.user_full_name }}
                      </div>
                      <p class="mt-2">
                        {{ item.justification }}
                      </p>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-h4 font-weight-medium">
              Correciones de notas
            </div>
            <v-sheet
              rounded="xl"
              color="white"
              elevation="2"
              class="mx-auto py-5 px-7 my-5"
            >
              <v-sheet class="d-none d-sm-flex flex-row align-center">
                <v-container>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="2"
                      class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    >
                      Código
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    >
                      Nombre completo
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    >
                      Especialidad
                    </v-col>
                    <v-col
                      cols="12"
                      sm="1"
                      class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    >
                      {{
                        request.subject_type_id == 1
                          ? "Nivel de logro a sustituir"
                          : "Nota a sustituir"
                      }}
                    </v-col>
                    <v-col
                      cols="12"
                      sm="1"
                      class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    >
                      {{
                        request.subject_type_id == 1
                          ? "Nivel de logro nuevo"
                          : "Nota nueva"
                      }}
                    </v-col>
                    <v-col
                      cols="12"
                      sm="1"
                      class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    >
                      Aprobado
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
              <v-hover
                v-slot="{ hover }"
                v-for="(student, index) in request.score_correction_details"
                :key="student.code"
              >
                <v-sheet
                  :elevation="hover ? 5 : 0"
                  class="d-flex flex-column flex-sm-row align-md-center mb-1"
                  :color="`grey lighten-${index % 2 ? 3 : 5}`"
                  rounded="xl"
                >
                  <v-container class="py-0">
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="2"
                        class="d-flex justify-center align-center text-h4 font-weight-medium pr-sm-3 mt-4 mt-sm-0"
                      >
                        {{ student.code }}
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        class="d-flex justify-center justify-sm-start align-center text-body-1 text-center text-sm-left font-weight-medium text-uppercase mb-2 mb-sm-0"
                      >
                        <div>
                          {{ student.full_name }}
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        class="d-flex justify-center align-center pa-0 ma-0"
                      >
                        {{ student.technical_group }}
                      </v-col>
                      <v-col
                        cols="12"
                        sm="1"
                        class="d-flex justify-center align-left pa-0 ma-0"
                      >
                        <span class="text-h5 my-auto">
                          {{ student.current_score }}
                        </span>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="1"
                        class="d-flex justify-center align-left pa-0 ma-0"
                      >
                        <v-chip
                          color="blue darken-1"
                          dark
                          class="text-h5 font-weight-medium my-3"
                        >
                          {{ student.new_score }}
                        </v-chip>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="1"
                        class="d-flex justify-center justify-sm-start align-center text-body-1 text-center text-sm-left font-weight-medium text-uppercase mb-2 mb-sm-0"
                      >
                        {{ student.approved_formatted }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-hover>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RequestDetailModal",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    request: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
